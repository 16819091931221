<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 30 30"
    style="enable-background: new 0 0 30 30"
    xml:space="preserve"
  >
    <path
      d="M16.7,13.6l6.8-7.9h-1.6L16,12.5l-4.7-6.8H5.9L13,16l-7.1,8.2h1.6l6.2-7.2l5,7.2h5.4L16.7,13.6L16.7,13.6z M14.5,16.1
	l-0.7-1L8.1,6.9h2.5l4.6,6.6l0.7,1l6,8.6h-2.5L14.5,16.1L14.5,16.1z"
      fill="currentColor"
    />
  </svg>
</template>
